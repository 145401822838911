.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #003A5A;
  font-size: 22px;
  line-height: 1.09090909;
  cursor: pointer;
  color: #dbb965;
  padding: 6px 34px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus,
.unit--isAnchor:hover .button {
  background-color: #dbb965;
  color: #003A5A;
}
.section--two .subcontent.subcontent--1 {
  width: 66.66666667%;
}
.section--two .subcontent.subcontent--2 {
  width: 33.33333333%;
}
.section--five .subcontent.subcontent--1 {
  width: 33.33333333%;
}
.section--five .subcontent.subcontent--2 {
  width: 66.66666667%;
}
.area--one .unitOne--slim {
  max-width: 688px;
  margin-left: calc((100% - 688px) / 2);
  margin-right: calc((100% - 688px) / 2);
}
.area--one .unitTwo {
  width: calc(((100% / 3) - var(--deskSpace)));
}
.area--four .unitOne--2-3 {
  width: 66.66666667%;
}
.area--four .unitOne--1-3 {
  width: 33.33333333%;
}
.area--four .unitTwo {
  width: 33.33333333%;
}
/*# sourceMappingURL=./screen-large.css.map */